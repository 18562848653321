import DCAInstructionsCriteoRetailMedia from '../components/DCAInstructions/DCAInstructionsCriteoRetailMedia';
import DCAInstructionsVCPOS from '../components/DCAInstructions/DCAInstructionsVCPOS';

// will want to expand (or delete) this when we want to show all subscription settings
export const OrganizationAdminVisibleOfferedSubscriptions: ReadonlyArray<string> = [
  'e56afec2-771e-4b6a-98db-11326b77b46e', // Amazon (US) vendor_central_pos
  '39785a70-e7c2-4f62-95da-091640333e91', // Amazon (UK) vendor_central_pos
  '0a1f1fdb-e897-4d4f-ae51-68989a777050', // Amazon (CA) vendor_central_pos
  '6ab62032-6174-459a-a948-2980cee2df1c', // Amazon (MX) vendor_central_pos
  '886cb5c4-ea08-4bc5-a8af-545eb15c9d11', // Target Advertising (Criteo Retail Media)
  '0bab3019-d8c9-4774-91c0-d01572323a9e', // Lowes Advertising (Criteo Retail Media)
  // '6ed4de27-5ed1-4b41-b775-5afbde94351c', // Amazon Search Advertising - for testing ONLY
];

export const SubscriptionQueryTranslations: { [key: string]: { [queryParam: string]: string } } = {
  // Amazon (US) vendor_central_pos
  'e56afec2-771e-4b6a-98db-11326b77b46e': {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  // Amazon (UK) vendor_central_pos
  '39785a70-e7c2-4f62-95da-091640333e91': {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  // Amazon (CA) vendor_central_pos
  '0a1f1fdb-e897-4d4f-ae51-68989a777050': {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  // Amazon (MX) vendor_central_pos
  '6ab62032-6174-459a-a948-2980cee2df1c': {
    selling_partner_id: 'selling_partner_id',
    spapi_oauth_code: '__spapi_oauth_code',
  },
  // Target Advertising
  '886cb5c4-ea08-4bc5-a8af-545eb15c9d11': {
    code: '__criteo_oauth_code',
  },
  // Lowes Advertising
  '0bab3019-d8c9-4774-91c0-d01572323a9e': {
    code: '__criteo_oauth_code',
  },
};

export const SubscriptionSettingUniqueValues: { [key: string]: string[] } = {
  // Amazon (US) vendor_central_pos
  'e56afec2-771e-4b6a-98db-11326b77b46e': [
    'account_name',
    'selling_partner_id',
  ],
  // Amazon (UK) vendor_central_pos
  '39785a70-e7c2-4f62-95da-091640333e91': [
    'account_name',
    'selling_partner_id',
  ],
  // Amazon (CA) vendor_central_pos
  '0a1f1fdb-e897-4d4f-ae51-68989a777050': [
    'account_name',
    'selling_partner_id',
  ],
  // Amazon (MX) vendor_central_pos
  '6ab62032-6174-459a-a948-2980cee2df1c': [
    'account_name',
    'selling_partner_id',
  ],
  // Target Advertising
  '886cb5c4-ea08-4bc5-a8af-545eb15c9d11': [
    'account_name',
  ],
  // Lowes Advertising
  '0bab3019-d8c9-4774-91c0-d01572323a9e': [
    'account_name',
  ],
};

// https://developer-docs.amazon.com/amazon-shipping/docs/seller-central-urls
export const amazonSpAPIVendorCentralAuthUrls: { [marketplaceCode: string]: string } = {
  CA: 'https://vendorcentral.amazon.ca/apps/authorize/consent',
  MX: 'https://vendorcentral.amazon.com.mx/apps/authorize/consent',
  UK: 'https://vendorcentral.amazon.co.uk/apps/authorize/consent',
  US: 'https://vendorcentral.amazon.com/apps/authorize/consent',
};

export const getAmazonSpAPIVendorCentralAuthUrl = (
  marketplaceCodes: string[],
): string | null => (
  (marketplaceCodes.length > 0 && marketplaceCodes[0] in amazonSpAPIVendorCentralAuthUrls)
    ? amazonSpAPIVendorCentralAuthUrls[marketplaceCodes[0]]
    : null
);

// https://consent.criteo.com/request?response_type=code&client_id={client_id}&redirect_uri={redirect_uri}&state={state}
export const criteoAuthUrl = 'https://consent.criteo.com/request';
// client id = a26d6d2c5430499bb13f922b481deafd
// redirect_uri = 'https://localhost.dev.marshall.technology:3000/organizationAdmin'

export const paramsToHideOnRefresh = [
  'spapi_oauth_code',
  'code',
];

export const DisableAccountNameEntry: string[] = [
  '886cb5c4-ea08-4bc5-a8af-545eb15c9d11', // TARGET Criteo Retail Media ADVERTISING
  '0bab3019-d8c9-4774-91c0-d01572323a9e', // LOWES Criteo Retail Media ADVERTISING
];

export const dcaInstructions: { [key: string]: JSX.Element } = {
  'e56afec2-771e-4b6a-98db-11326b77b46e': DCAInstructionsVCPOS,
  '39785a70-e7c2-4f62-95da-091640333e91': DCAInstructionsVCPOS,
  '0a1f1fdb-e897-4d4f-ae51-68989a777050': DCAInstructionsVCPOS,
  '6ab62032-6174-459a-a948-2980cee2df1c': DCAInstructionsVCPOS,
  // ! Needs a new page.  will worry about this later
  '886cb5c4-ea08-4bc5-a8af-545eb15c9d11': DCAInstructionsCriteoRetailMedia, // TARGET Criteo Retail Media ADVERTISING
  '0bab3019-d8c9-4774-91c0-d01572323a9e': DCAInstructionsCriteoRetailMedia, // LOWES Criteo Retail Media ADVERTISING
  // used for testing
  subscription_id_vendor_central_pos: DCAInstructionsVCPOS,
};
